body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.countries.ct-chart .ct-series.ct-series-a .ct-slice-pie {
  fill: #59922b;
}

.countries.ct-chart .ct-series.ct-series-b .ct-slice-pie {
  fill: #f4c63d;
}

.countries.ct-chart .ct-series.ct-series-c .ct-slice-pie {
  fill: #0544d3;
}

.countries.ct-chart .ct-series.ct-series-d .ct-slice-pie {
  fill: #fff3f3
}

.countries.ct-chart .ct-series.ct-series-e .ct-slice-pie {
  fill: #d70206;
}

.countries.ct-chart .ct-series.ct-series-f .ct-slice-pie {
  fill: #453d3f;
}

.countries.ct-chart .ct-series.ct-series-g .ct-slice-pie {
  fill: #1E88E5;
}

.players.ct-chart .ct-series.ct-series-b .ct-bar {
  stroke: #80CBC4
}

.players.ct-chart .ct-series.ct-series-d .ct-bar {
  stroke: #1E88E5
}

.pyramids.ct-chart .ct-series.ct-series-b .ct-bar {
  stroke: #1E88E5
}

.pyramids.ct-chart .ct-series.ct-series-c .ct-bar {
  stroke: #c7c7c7
}

.pyramids.ct-chart .ct-series.ct-series-d .ct-bar {
  stroke: #453d3f;
}

.standalone.ct-chart .ct-series.ct-series-a .ct-bar {
  stroke: #1E88E5
}

.standalone.ct-chart .ct-series.ct-series-b .ct-bar {
  stroke: #80CBC4
}

/*
acc[0][id].add(blue);
acc[1][id].add(green);
acc[2][id].add(yellow);
acc[3][id].add(purple);
acc[4][id].add(wonder);
acc[5][id].add(marker);
acc[6][id].add(coin);
acc[7][id].add(battle);
*/

.duel.ct-chart .ct-series.ct-series-a .ct-bar {
  stroke: #0544d3;
}

.duel.ct-chart .ct-series.ct-series-b .ct-bar {
  stroke: #59922b;
}

.duel.ct-chart .ct-series.ct-series-c .ct-bar {
  stroke: #FFFF00;
}

.duel.ct-chart .ct-series.ct-series-d .ct-bar {
  stroke: #6b0392
}

.duel.ct-chart .ct-series.ct-series-e .ct-bar {
  stroke: #c7c7c7
}

.duel.ct-chart .ct-series.ct-series-f .ct-bar {
  stroke: #453d3f;
}

.duel.ct-chart .ct-series.ct-series-g .ct-bar {
  stroke: #FFD700;
}

.duel.ct-chart .ct-series.ct-series-h .ct-bar {
  stroke: #d70206;
}
